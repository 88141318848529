import { lazy } from "react";
import { Navigate } from "react-router-dom";
import FileDetails from "../components/dashboard/FileDetails.js";
import UnauthorizedPage from "../views/Unauthorized.js";
import { getToken } from "../services/LocalStorageService";
import jwtDecode from "jwt-decode";
/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/
const CreateProjectFormWithClient = lazy(() =>
  import("../views/admin/CreateProjectFormWithClient.js")
);
const Starter = lazy(() => import("../views/Starter.js"));
const SassConversion = lazy(() => import("../views/SassConversion.js"));
const SnowflakeConversion = lazy(() => import("../views/SnowflakeConversion.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Assessment = lazy(() => import("../views/Assessment.js"));
const Login = lazy(() => import("../Login.js"));
const Register = lazy(() => import("../Register.js"));
const Admin = lazy(() => import("../views/admin/admin.js"));
const Upload = lazy(() => import("../views/UploadPage.js"));
const Client = lazy(() => import("../views/admin/Client.js"));
const ClientForm = lazy(() => import("../views/admin/ClientForm.js"));
const ClientList = lazy(() => import("../views/admin/ClientList.js"));
const CreateProjectForm = lazy(() =>
  import("../views/admin/CreateProjectForm.js")
);
const CreateClientForm = lazy(() =>
  import("../views/admin/CreateClientForm.js")
);
const UserInfoComponent = lazy(() =>
  import("../views/admin/UserInfoComponent.js")
);
const ProjectForm = lazy(() => import("../views/admin/ProjectForm.js"));
const ProjectList = lazy(() => import("../views/admin/ProjectList.js"));
const PostConversion = lazy(() => import("../views/TablePostConversion.js"));
const PostConversionAlx = lazy(() => import("../views/PostConversionAlx.js"));
const Trello = lazy(() => import("../views/Trello.js"));
const AssesmentLot = lazy(() =>
  import("../components/dashboard/AssessmentLot.js")
);
const UpdateClient = lazy(() => import("../views/admin/UpdateClient.js"));
const CreateBatch = lazy(() => import("../views/CreateBatch.js"));
const CreateDDL = lazy(() => import("../views/CreateDDL.js"));
const GenerateDataset = lazy(() => import("../views/GenerateDataset.js"));
const Landing = lazy(() => import("../views/LandingManager.js"));
const TableAssessed = lazy(() => import("../views/Tables/TableAssessed.js"));
const TableConverted = lazy(() => import("../views/Tables/TableConverted.js"));
const TableTested = lazy(() => import("../views/Tables/TableTested.js"));
const TableValidated = lazy(() => import("../views/Tables/TableValidated.js"));
const TableAssigned = lazy(() => import("../views/Tables/TableAssigned.js"));
const AssignReviewer = lazy(() =>
  import("../components/dashboard/AssignReviewer.js")
);
const UserDetails = lazy(() =>
  import("../components/dashboard/UserDetails.js")
);
const ProjectDetailsGrid = lazy(() =>
  import("../views/admin/ProjectDetailsGrid.js")
);
const UserProfile = lazy(() => import("../layouts/UserProfile.js"));

const Postconversion = lazy(() => import("../views/Postconversion.js"));
const ProjectList2 = lazy(() => import("../views/admin/ProjectList2.js"));
const TableInprogress = lazy(() =>
  import("../views/Tables/TableInprogress.js")
);

const ProtectedRoute = ({ element, requiredRole }) => {
  console.log("requiredRoles:", requiredRole); // Add this line
  const { access_token } = getToken();

  if (!access_token) {
    return <Navigate to="/login" replace />;
  }

  const decodedToken = jwtDecode(access_token);
  console.log("decodedToken.profile:", decodedToken.profile); // Add this line

  // Check if the token has expired
  const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
  if (decodedToken.exp < currentTime) {
    // Token has expired, redirect to login
    return <Navigate to="/login" replace />;
  }

  if (requiredRole.includes(decodedToken.profile)) {
    return element;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

const ThemeRoutes = [
  {
    element: <FullLayout />,
    children: [
      // { path: "/", element: <Login /> },
      { path: "/starter", exact: true, element: <Starter /> },
      { path: "/assessed_files", exact: true, element: <TableAssessed /> },
      { path: "/assigned_files", exact: true, element: <TableAssigned /> },
      { path: "/converted_files", exact: true, element: <TableConverted /> },
      { path: "/tested_files", exact: true, element: <TableTested /> },
      { path: "/validated_files", exact: true, element: <TableValidated /> },
      { path: "/inprogress_files", exact: true, element: <TableInprogress /> },
      { path: "/postconversion", exact: true, element: <PostConversion /> },
      {
        path: "/ProjectList2/:id",
        exact: true,
        element: (
          <ProtectedRoute element={<ProjectList2 />} requiredRole="admin" />
        ),
      },
      {
        path: "/assign_review/:id",
        exact: true,
        element: (
          <ProtectedRoute
            element={<AssignReviewer />}
            requiredRole="Reviewer"
          />
        ),
      },
      { path: "/UserDetails", exact: true, element: <UserDetails /> },
      {
        path: "/UserInfoComponent/:id",
        exact: true,
        element: (
          <ProtectedRoute
            element={<UserInfoComponent />}
            requiredRole="admin"
          />
        ),
      },

      {
        path: "/CreateProjectFormWithClient",
        exact: true,
        element: (
          <ProtectedRoute
            element={<CreateProjectFormWithClient />}
            requiredRole="admin"
          />
        ),
      },
      {
        path: "/sas_to_py/:id",
        exact: true,
        element: (
          <ProtectedRoute
            element={<SassConversion />}
            requiredRole="Developer"
          />
        ),
      },
      {
        path: "/sas_to_snflk/:id",
        exact: true,
        element: (
          <ProtectedRoute
            element={<SnowflakeConversion />}
            requiredRole="Developer"
          />
        ),
      },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
      {
        path: "/CreateBatch",
        exact: true,
        element: (
          <ProtectedRoute
            element={<CreateBatch />}
            requiredRole="Project Manager"
          />
        ),
      },
      {
        path: "/CreateDDL",
        exact: true,
        element: (
          <ProtectedRoute
            element={<CreateDDL />}
            requiredRole="Project Manager"
          />
        ),
      },
      {
        path: "/GenerateDataset",
        exact: true,
        element: (
          <ProtectedRoute
            element={<GenerateDataset />}
            requiredRole="Project Manager"
          />
        ),
      },
      {
        path: "/code_assessment",
        exact: true,
        element: (
          <ProtectedRoute
            element={<Assessment />}
            requiredRole={["Project Manager", "Developer", "Reviewer"]}
            // requiredRole="developer"
          />
        ),
      },
      {
        path: "/admin",
        exact: true,
        element: <ProtectedRoute element={<Admin />} requiredRole="admin" />,
      },
      {
        path: "/upload",
        exact: true,
        element: (
          <ProtectedRoute element={<Upload />} requiredRole="Project Manager" />
        ),
      },
      {
        path: "/Client",
        exact: true,
        element: <ProtectedRoute element={<Client />} requiredRole="admin" />,
      },
      {
        path: "/updateclient/:id",
        exact: true,
        element: (
          <ProtectedRoute element={<UpdateClient />} requiredRole="admin" />
        ),
      },
      {
        path: "/ClientList",
        exact: true,
        element: (
          <ProtectedRoute element={<ClientList />} requiredRole="admin" />
        ),
      },
      {
        path: "/ClientForm",
        exact: true,
        element: (
          <ProtectedRoute element={<ClientForm />} requiredRole="admin" />
        ),
      },
      {
        path: "/kanban",
        exact: true,
        element: (
          <ProtectedRoute
            element={<Trello />}
            requiredRole={["Developer", "Reviewer"]}
          />
        ),
      },
      { path: "/unauthorized", exact: true, element: <UnauthorizedPage /> },

      {
        path: "/CreateProjectForm",
        exact: true,
        element: (
          <ProtectedRoute
            element={<CreateProjectForm />}
            requiredRole="admin"
          />
        ),
      },
      { path: "/UserProfile", exact: true, element: <UserProfile /> },
      {
        path: "/CreateClientForm",
        exact: true,
        element: (
          <ProtectedRoute element={<CreateClientForm />} requiredRole="admin" />
        ),
      },
      {
        path: "/ProjectForm",
        exact: true,
        element: (
          <ProtectedRoute element={<ProjectForm />} requiredRole="admin" />
        ),
      },
      {
        path: "/ProjectList/:id",
        exact: true,
        element: (
          <ProtectedRoute element={<ProjectList />} requiredRole="admin" />
        ),
      },

      {
        path: "/postconversionalx/:id",
        exact: true,
        element: (
          <ProtectedRoute
            element={<PostConversionAlx />}
            requiredRole="Reviewer"
          />
        ),
      },
      {
        path: "/ProjectDetailsGrid/:id",
        exact: true,
        element: (
          <ProtectedRoute
            element={<ProjectDetailsGrid />}
            requiredRole="admin"
          />
        ),
      },
      {
        path: "/assign/:id",
        exact: true,
        element: (
          <ProtectedRoute
            element={<FileDetails />}
            requiredRole="Project Manager"
          />
        ),
      },
      {
        path: "Landing",
        exact: true,
        element: (
          <ProtectedRoute
            element={<Landing />}
            requiredRole="Project Manager"
          />
        ),
      },
      {
        path: "/Assessment",
        exact: true,
        element: (
          <ProtectedRoute
            element={<AssesmentLot />}
            requiredRole="Project Manager"
          />
        ),
      },

      {
        path: "/PostConversion",
        exact: true,
        element: <PostConversion />,
      },
    ],
  },
  { path: "/login", exact: true, element: <Login /> },
  { path: "/register", exact: true, element: <Register /> },
];

export default ThemeRoutes;
