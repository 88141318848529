import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button, Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

const UnauthorizedPage = () => {
  return (
    <Container>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid item>
          <LockIcon style={{ fontSize: 100, color: "rgb(255, 230, 0)" }} />
        </Grid>
        <Grid item>
          <Typography variant="h4" align="center" gutterBottom>
            Oops! Access Denied
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Sorry, you don't have permission to access this page.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/login"
            variant="contained"

style={{backgroundColor:'rgb(255, 230, 0)', color:'black'}}>
            Go to Login
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UnauthorizedPage;
